@import "../../utils/GlobalStyles.scss";

//JUSTIFIED LEFT VERSION


.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Vertically center items */
  justify-content: space-between;
  margin: 0 7px 7px 7px;
  border-bottom: $line-width solid $border-color;
  position: relative; // Add relative positioning to the parent

  @media (max-width: calc(4 * $min-card-width)) {
    flex-direction: column;
    align-items: center; // Center items horizontally in column layout
    justify-content: flex-start; // Start alignment in column layout
    margin: 14px auto; // Adjust margin for smaller screens
    padding-top: 10%; // Add some padding for better spacing
    padding-bottom: 5%;
    padding-left: 15%;
    padding-right: 15%;
  }
}

.profile-name-title img {
  width: calc($min-card-width/5);
  height: calc($min-card-width/5);
  border-radius: 50%;

  @media (max-width: calc(6 * $min-card-width)) {
    width: calc($min-card-width/6);
  height: calc($min-card-width/6);
  }

  @media (max-width: calc(4 * $min-card-width)) {
    width: calc($min-card-width/5);
  height: calc($min-card-width/5);
  }
}

.profile-name-title {
  display: flex;
  padding: 1.5% 0.5%;
  align-items: center;

  @media (max-width: calc(4 * $min-card-width)) {
    flex-direction: column;
    padding: 1% 0 ;
  }
}

.name-title {
  line-height: 0.8;
  @media (max-width: calc(4 * $min-card-width)) {
    padding: 20px;
    }
}

.name {
  color: $border-color;
  width: 10%;
  font-size: $smaller-font-size;
  margin: 10px;
}

.title {
  width: 10%;
  color: $standard-font-colour;
  font-size: $smaller-font-size;
  margin: 10px;
}


.contact-button {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;

  background-color: $secondary-background-color; // Same as card background
  color: $primary-text-color; // Primary text color for contrast
  border: $line-width solid $border-color; // Border similar to cards

  border-radius: 14px;
  padding: 3px 12px;
  font-size: $standard-font-size;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.6;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12); // Enhanced shadow on hover
    opacity: 1; // Full opacity on hover
    text-decoration: none;
    transform: translateY(-2px); // Slight lift on hover/focus

  }
}

.shuffle-layout-button {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;

  background-color: #2979ff; // Bright blue for visibility
  color: $primary-text-color; // Keep the primary text color for contrast
  border: $line-width solid $border-color; // Keep the border similar to cards
  margin: 0px 10px;

  border-radius: 14px;
  padding: 6px 12px;
  font-size: $standard-font-size;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s, border-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0.6;

  // Apply the pulse animation
  animation: pulse-glow 2s infinite;

  &:hover {
    box-shadow: 0 0 8px rgba(41, 121, 255, 0.8); // Glowing shadow for hover/focus
    opacity: 1; // Full opacity on hover/focus
    border-color: #80d8ff; // Lighter border color on hover/focus for the glow effect
    text-decoration: none;
    transform: translateY(-2px); // Slight lift on hover/focus
  }

}

.MuiSvgIcon-root {
  font-size: $standard-font-size * 1.1 !important; // The !important is to override MUI default styles if necessary
}

@keyframes pulse-glow {

  0%,
  100% {
    box-shadow: 0 0 8px rgba(41, 121, 255, 0.6);
  }

  50% {
    box-shadow: 0 0 16px rgba(41, 121, 255, 1);
  }
}

@keyframes pulse-glow {

  0%,
  100% {
    box-shadow: 0 0 8px rgba(41, 121, 255, 0.6);
  }

  50% {
    box-shadow: 0 0 20px rgba(41, 121, 255, 0.9); // Increased glow for visibility
  }
}

// Other styles remain unchanged...

.shuffle-layout-button,
.contact-button {
  display: flex;
  align-items: center; // Align items in the center vertically
  justify-content: center; // Center content horizontally 

  .button-text {
    display: inline-block;
  }

  .button-icon {
    // Align the icon with the text
    display: inline-block;
    margin-left: 4px; // Adjust spacing between text and icon
  }
}

// Other styles remain unchanged...

.right-container {
  display: flex;
}
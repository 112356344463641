@import '../../utils/GlobalStyles.scss';

.contact-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connect-button {
    background-color: $secondary-background-color; // Same as card background
    color: $primary-text-color; // Primary text color for contrast
    border: $line-width solid $border-color; // Border similar to cards
    border-radius: 14px;
    padding: 3px 0px;
    margin: 3% 0px;
    font-size: $standard-font-size;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0.6;
    text-wrap: nowrap;
    width: 70%;

    display: flex;
  align-items: center; // Align items in the center vertically
  justify-content: center; // Center content horizontally 

    &:hover {
        background-color: $tertiary-background-color;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12); // Enhanced shadow on hover
        opacity: 1; // Full opacity on hover
        text-decoration: none;
    }

    .button-icon {
        // Align the icon with the text
        display: inline-block;
        margin-left: 4px; // Adjust spacing between text and icon
      }
}

.MuiSvgIcon-root {
    font-size: $standard-font-size * 1.1 !important; // The !important is to override MUI default styles if necessary
  }
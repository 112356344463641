@import '../../utils/GlobalStyles.scss';

.skills-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.skills-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
}

.skills-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4px;
    padding-bottom: 10px;
    margin: 0px 10px;
    border-bottom: 1px solid $border-color;

    .skills-title {
        font-size: $standard-font-size;
        font-weight: $standard-font-weight;
        color: $important-font-colour;
        margin-bottom: 4px; // Space between title and certified icon
    }

    .certified-section {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: gold;
        font-size: $standard-font-size;
        position: relative;
        margin-right: 4px;
        text-shadow: 0 0 20px gold; // Adds glow to the text

        &:hover {
            text-shadow: 0 0 50px gold;
        }
    
        // Add this to override the cursor for non-clickable items
        &.non-clickable {
            cursor: default;
        }

        .certified-label {
            margin-right: 4px;
        }

        .certified-icon {
            font-size: $standard-font-size * 1.1;
        }
    }

    .completed-section {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: violet;
        font-size: $standard-font-size;
        position: relative;
        margin-right: 4px;
        text-shadow: 0 0 20px violet; // Adds glow to the text

        &:hover {
            text-shadow: 0 0 50px violet;
        }
    
        // Add this to override the cursor for non-clickable items
        &.non-clickable {
            cursor: default;
        }

        .completed-label {
            margin-right: 4px;
        }

        .certified-icon {
            font-size: $standard-font-size * 1.1;
        }
    }
}
@import '../../utils/GlobalStyles.scss';

.education-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; // Aligns items to the start (top) of the container
    width: 100%; // Full width
    height: 100%; // Full height to occupy the card
}

.education-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%; // Full width of the container
}

.education-item {
    padding-top: 4px;
    padding-bottom: 10px;
    margin: 0px 10px;
    border-bottom: 1px solid $border-color;
    transition: background-color 0.2s;

    &.degree-item {
        border-radius: 4px;
        background-color: black; // Choose a distinct color
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Optional: add a shadow for emphasis
        border-left: 4px solid purple; // Optional: add a highlight border
        margin: 10px 10px;
        padding: 5px 10px;
    }

    &.a-levels-item {
        border-radius: 4px;
        background-color: black; // Choose a distinct color
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Optional: add a shadow for emphasis
        border-left: 4px solid orange; // Optional: add a highlight border
        margin: 10px 10px;
        padding: 5px 10px;
    }

    .education-title {
        font-size: $standard-font-size;
        font-weight: $standard-font-weight;
        color: $important-font-colour;
    }

    .education-grad,
    .education-location {
        color: $standard-font-colour;
        font-size: $standard-font-size; // Smaller font size for the short description
        padding-top: 4px; // Space between title and short description 
    }
}


.certification-button {
    background-color: $secondary-background-color;
    color: $primary-text-color;
    border: $line-width solid $border-color;
    border-radius: 14px;
    padding: 3px 0px;
    margin: 3% 0px 0px 0px;
    font-size: $standard-font-size;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0.6;
    text-wrap: nowrap;
    width: 100px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: $tertiary-background-color;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
        opacity: 1;
        text-decoration: none;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: inherit; // Ensure link color matches the button text color

        .button-content {
            display: flex;
            align-items: center;
        }

        .button-icon {
            margin-left: 4px;
        }
    }
}

.MuiSvgIcon-root {
    font-size: $standard-font-size * 1.1 !important; // The !important is to override MUI default styles if necessary
  }
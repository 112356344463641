@import '../../utils/GlobalStyles.scss';

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: background-color 0.3s, opacity 1s; /* Longer transition for opacity */
}

.modal {
  padding: 20px;
  border-radius: 5px;
  z-index: 1001;
  opacity: 0;
  transition: opacity 1s;
}


.modal-backdrop.open {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.modal.open {
  opacity: 1;
  animation: fadeIn 0.3s; /* Define the fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@import "../../utils//GlobalStyles.scss";

.status-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;

  .text-container {
    max-width: 80%;
      
    background-color: white;
    box-shadow: 2px 5px 3px rgba(0, 0, 0);
    margin: 3px 10px;
    border: $line-width solid black;
    border-radius: 8px;

    .status-text {
      
      text-align: center;
      font-size: $standard-font-size;
      color: $border-color;
      padding: 5px;
    }
  }
}

.status-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 0 8px 2px;
}

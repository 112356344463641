// GlobalStyles.scss

// Define your variables
$primary-background-color: #f5ebe0;
$secondary-background-color: #1e1e1e;
$tertiary-background-color: #455c56;
$primary-text-color: #fff;
$secondary-text-color: #666666;
$border-color: #545454;
$line-width: 1px;

$smaller-font-size: 0.79rem;

$standard-font-size: 0.75rem;
$standard-font-weight: 200;

$bigger-font-size: 0.85rem;
$bigger-font-weight: 300;

$standard-font-colour: #999;
$important-font-colour: #e0e0e0;

$min-card-width: 200px; // Adjust this value as needed

@font-face {
  font-family: 'Quicksand';
  src: url('../assets/fonts/Quicksand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets//fonts/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets//fonts/Quicksand-Light.ttf') format('truetype');
  font-weight: 300; // Light
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets//fonts/Quicksand-Medium.ttf') format('truetype');
  font-weight: 500; // Medium
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets//fonts/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 600; // Semi-Bold
  font-style: normal;
}



// Common styles


/* Add scrollbar styling */
::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: $tertiary-background-color; /* Customize the scrollbar thumb color */
    border-radius: 5px; /* Round the corners of the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: $primary-background-color; /* Customize the thumb color on hover */
  }
  
  ::-webkit-scrollbar-track-piece {
    background-color: transparent; /* Customize the scrollbar track piece color (if needed) */
  }
  
  /* Add more scrollbar styling rules as needed */
  
@import './utils/GlobalStyles.scss';

// Global styles
* {
  box-sizing: border-box;
}

a {
  color: $primary-background-color;
  text-decoration: none; // Remove underline from links

  &:hov
  &:hover {
    text-decoration: underline; // Add underline on hover for links
  }
}

html, body {
  margin: 0;
  padding: 0;
  background-color: $primary-background-color;
  color: $primary-text-color;
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  line-height: 1.6;
  overflow: hidden;
}

html {
  overflow-y: scroll;
  
  
/* Add scrollbar styling */
::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: $tertiary-background-color; /* Customize the scrollbar thumb color */
    border-radius: 5px; /* Round the corners of the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: $primary-background-color; /* Customize the thumb color on hover */
  }
  
  ::-webkit-scrollbar-track {
    border-left: none; // Removes the left border for this specific scrollbar
    background-color: transparent; // Keeps the track background transparent for this scrollbar
}
  
  ::-webkit-scrollbar-track-piece {
    background-color: transparent; /* Customize the scrollbar track piece color (if needed) */
  }
  
}

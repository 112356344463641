@import '../utils/GlobalStyles.scss';


@keyframes dropIn {
    0% {
        opacity: 0;
        transform: translateY(-100px) scaleY(0.85);
    }

    50% {
        opacity: 0.5;
        transform: translateY(15px) scaleY(1.1);
    }

    65% {
        transform: translateY(-10px) scaleY(0.95);
    }

    80% {
        transform: translateY(5px) scaleY(1.05);
    }

    90% {
        transform: translateY(-2px) scaleY(0.98);
    }

    100% {
        opacity: 0.6;
        transform: translateY(0) scaleY(1);
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.page-framer {
    animation: fadeInAnimation ease 2s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 100vh;
    display: flex;
    flex-direction: column;

    /* Stack header and main content vertically */

    .page-centering {
        margin-top: auto;
        margin-bottom: auto;

    .main-content-framer {
        display: grid;
        grid-template-columns: repeat(6, 190px); // Six columns
        grid-template-rows: repeat(4, 190px); // Four rows

        width: 100%;
        max-height: calc(4 * var(--grid-object-height)); // Max height based on 4 rows

        // Media query for intermediate layout
        @media (max-width: calc(6 * $min-card-width)) {
            grid-template-columns: repeat(4, 190px); // Intermediate layout: 4 columns
            grid-template-rows: repeat(6, 190px); // Intermediate layout: 6 rows
            max-height: calc(6 * var(--grid-object-height)); // Max height based on 6 rows
        }

        // Media query for final layout
        @media (max-width: calc(4 * $min-card-width)) {
            grid-template-columns: repeat(2, 205px); // Final layout: 2 columns
            grid-template-rows: repeat(12, 205px); // Final layout: 12 rows
            max-height: calc(12 * var(--grid-object-height)); // Max height based on 12 rows

        }

        .card {
            min-width: 0; // Prevents stretching
            max-width: 100%; // Ensures it doesn't grow beyond the cell
            min-height: 0; // Prevents stretching
            max-height: 100%; // Ensures it doesn't grow beyond the cell
            // Apply any other common styles here

            animation-name: dropIn;
            animation-duration: 1.2s; // Slightly longer duration for a more natural feel
            animation-timing-function: ease-in-out; // Smoother transitions
            animation-fill-mode: backwards;

            ::-webkit-scrollbar-track {
                background-color: transparent; /* Customize the scrollbar track color */
                border-left: $line-width solid $border-color; // Add a border to the left side of the track
              }

        }

        // Adjust card sizes within the main content framer
        .six-columns {
            grid-column: span 6; // Full width across all six columns
        }

        .five-columns {
            grid-column: span 5; // Full width across all six columns
        }

        .four-columns {
            grid-column: span 4; // Full width across all six columns
        }

        .three-columns {
            grid-column: span 3; // Half width, covering three columns
        }

        .two-columns {
            grid-column: span 2; // One-third width, covering two columns
        }

        .one-column {
            grid-column: span 1; // One-third width, covering two columns
        }

        .four-rows {
            grid-row: span 4; // Full height across all four rows
        }

        .three-rows {
            grid-row: span 3; // Three quarters height, covering three rows
        }

        .two-rows {
            grid-row: span 2; // Half height, covering two rows
        }

        .one-row {
            grid-row: span 1; // Quarter height, covering one row
        }
    }
}
}
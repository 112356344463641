@import '../../utils/GlobalStyles.scss';

.projects-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    .special-project-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px;
        margin: 10px;
        transition: background-color 0.2s;
        border-radius: 10px;
        align-items: stretch;
        width: calc(100% - 20px);

        &:hover:not(.no-hover) {
            background-color: $tertiary-background-color;
        }

        .project-image-and-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 15px;
            justify-content: center;
        }

        .project-image-container {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
        }

        .project-image {
            max-width: 80%;
            max-height: 80%;
            border-radius: 30%;
            object-fit: contain;
        }

        .view-button {
            background-color: $secondary-background-color;
            color: $primary-text-color;
            border: $line-width solid $border-color;
            border-radius: 14px;
            padding: 0px 10px;
            cursor: pointer;
            transition: background-color 0.2s, box-shadow 0.3s, opacity 0.3s;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            opacity: 0.6;
            font-size: $standard-font-size;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: $tertiary-background-color;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
                opacity: 1;
            }

            .button-icon {
                margin-left: 4px;
                font-size: $standard-font-size * 1.1;
            }
        }

        .project-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .project-title {
                font-size: $standard-font-size;
                color: $important-font-colour;
            }

            .project-description {
                font-size: $standard-font-size;
                color: $standard-font-colour;
            }

            .project-stack {
                font-size: $standard-font-size;
                color: $standard-font-colour;
                margin-top: 5px;
            }
        }
    }

    .more-info-text {
        text-align: center;
        width: 100%;
        > span {
            font-size: $standard-font-size;
            color: $standard-font-colour;
        }
    }

    .projects-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        width: 100%;
    }

    .project-item {
        // Same styles as .special-project-item except the background color
        @extend .special-project-item;
        background-color: darken($secondary-background-color, 10%);
    }
}

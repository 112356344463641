@import "../../../utils/GlobalStyles.scss";

$mark-dims: 70%;
$hand-dims: calc($mark-dims/2);

.clock-container{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.clock {
    position: absolute;
    width:60%; // Clock takes full width of the container
    height: 60%; // Padding bottom equal to full width for a square aspect ratio
    background-color: $secondary-background-color;
    display: flex;
    align-items: center;  
    justify-content: center;
    margin-bottom: 20%;

  
  .hand {
    position: absolute;
    bottom: $hand-dims; // Centered vertically
    background-color: $important-font-colour;
    transform-origin: bottom;
    transition: transform 0.05s ease-in-out;
  }
  
  .hour-hand {
    width: 2%; // Responsive width
    height: 25%; // Responsive height
  }
  
  .minute-hand {
    width: 2%;
    height: 35%;
  }
  
  .second-hand {
    width: 1%;
    height: 40%;
    background-color: red;
  }

  .mark {
    position: absolute;
    bottom: $hand-dims;
    width: $mark-dims;
    height: $mark-dims;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center bottom;
  }
  
  .hour-mark .mark-line {
    width: 1%; // Reduced width
    height: 8%; // Height to fit within the clock's radius
    background-color: $standard-font-colour;
    transform: translateX(-50%) translateY(-300%); // Adjusting position to keep within bounds
  }
  
  .minute-mark .minute-mark-line {
    width: 1%; // Reduced width for minute marks
    height: 5%; // Height to fit within the clock's radius
    background-color: $border-color;
    transform: translateX(-50%) translateY(-600%); // Adjusting position to keep within bounds
  }
  
}
  
  
@import '../../utils/GlobalStyles.scss';

.card {
  display: flex;
  flex-direction: column;
  background-color: $secondary-background-color;
  /* Slightly lighter or darker for a subtle modern effect */
  border-radius: 8px;
  border: $line-width solid $border-color;
  /* Ensure the border is subtle */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Softer shadow for a sleeker look */
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 9px;
  opacity: 0.8;

  box-shadow: 3px 3px 3px rgba(0, 0, 0);

  max-height: 100%;
  max-width: 100%;

  @media (max-width: calc(4 * $min-card-width)) {
    margin: 5px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0);
}

  

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    /* Softer shadow on hover */
    opacity: 0.9;
  }

  .card-header {
    display: flex; // Set card-header as a flex container
    justify-content: space-between; // Space out children to the far ends
    border-bottom: $line-width solid #545454;
    margin: 0px 10px;

    .title,
    .extra {
      margin: 4px 0px;
      font-size: $standard-font-size;
      white-space: nowrap;
    }

    .title {
      flex-grow: 1;
      color: $important-font-colour;
      font-weight: $bigger-font-weight;
    }

    .extra {
      color: $standard-font-colour;
      font-weight: $standard-font-weight;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow-y: auto;
    min-height: 0;
    min-width: 0;


    transition: mask-image 0.3s ease-in-out;
  }

  p {
    color: $standard-font-colour;
    /* Lighter text color for content */
    font-size: $standard-font-size;
    /* Smaller font size for content */
    font-weight: $standard-font-weight;
  }
}
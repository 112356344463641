@import "../../utils/GlobalStyles.scss";

.experience-modal-content {
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 60vh;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border: $line-width solid $border-color;
  border-radius: 10px;
  color: $border-color; // Ensure text color is readable

  @media (max-width: calc(4 * $min-card-width)) {
    flex-direction: column;
    width: 350px;
  }

  .experience-list-modal {
    flex-basis: 30%;
    border-right: 1px solid $border-color;
    overflow-y: auto;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: rgba(245, 245, 245, 0.75);
    max-height: 60vh;
    padding: 20px 0px;

    @media (max-width: calc(4 * $min-card-width)) {
      display: none;
    }

    .experience-item-modal {
      cursor: pointer;
      padding: 10px;
      transition: background-color 0.2s;

      font-size: $standard-font-size;
      line-height: 1.5;

      &:hover {
        background-color: lighten(
          $tertiary-background-color,
          20%
        );
        color: $important-font-colour;
      }

      &.active {
        background-color: $tertiary-background-color;
        color: $important-font-colour;
      }
    }

    .experience-item-modal:not(:last-child) {
    border-bottom: 1px solid $border-color;
}
  }

  .experience-detail-modal {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(255, 255, 255, 0.93);
    width: 70%;
    overflow-y: auto; // Enable scrolling for the detail section

    @media (max-width: calc(4 * $min-card-width)) {
      flex-direction: column;
      align-items: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      width: 100%;
    }

    a {
      color: $standard-font-colour; // Set the color to match your standard text color
      text-decoration: underline; // Underline the link

      &:hover {
        text-decoration: none; // Optionally, remove underline on hover for a change effect
      }
    }

    .modal-header {
      display: flex;
      align-items: center;
      width: 100%;

      @media (max-width: calc(4 * $min-card-width)) {
        flex-direction: column;
        align-items: center;
      }

      .company-logo {
        width: 75px;
        height: auto;
        margin-right: 20px;

        @media (max-width: calc(4 * $min-card-width)) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      .modal-text {
        text-align: left;

        @media (max-width: calc(4 * $min-card-width)) {
          text-align: center;
        }

        h2 {
          color: $border-color;
          margin: 5px 0;
          font-size: calc($standard-font-size * 1.5);
        }

        h3 {
          color: $border-color;
          margin: 5px 0;
          font-size: calc($standard-font-size * 1.2);
        }
      }
    }

    p {
      color: $standard-font-colour;
      font-size: $standard-font-size;
      margin: 10px 0;

      @media (max-width: calc(4 * $min-card-width)) {
        text-align: justify;
      }
    }

    // For HTML content inside longdesc
    ul {
      list-style-type: disc;
      padding-left: 20px;
      font-size: $standard-font-size;
      color: $standard-font-colour;
      margin: 10px 0;
    }

    li {
      margin-bottom: 5px;
    }

    .skills {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: $standard-font-size;
      font-weight: bold;
      color: rgb(99, 99, 99);

      @media (max-width: calc(4 * $min-card-width)) {
        text-align: center;
      }
    }
  }
}

.experience-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; // Aligns items to the start (top) of the container
  width: 100%; // Full width
  height: 100%; // Full height to occupy the card
}

.experience-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%; // Full width of the container
}

.experience-item {
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 10px;
  margin: 0px 10px;
  border-bottom: 1px solid $border-color;
  transition: background-color 0.2s;

  &:hover {
    background-color: $tertiary-background-color;
    margin: 0; /* Remove margins */
    padding: 4px 10px 10px 10px; /* Adjust padding */
    border-bottom: 0px solid $border-color;

    .experience-title,
    .experience-shortdesc,
    .experience-location {
      color: $important-font-colour; // Change font color on hover
    }
  }

  .experience-title {
    font-size: $standard-font-size;
    font-weight: $standard-font-weight;
    color: $important-font-colour;
  }

  .experience-shortdesc,
  .experience-location {
    color: $standard-font-colour;
    font-size: $standard-font-size; // Smaller font size for the short description
    padding-top: 4px; // Space between title and short description
  }
}

@import '../../utils/GlobalStyles.scss';

.portfolio-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 3 columns
    gap: 5px; // Space between items
    padding: 8px;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: lighten($secondary-background-color, 20%);
    border-radius: 8px;

}

.portfolio-item {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%; // Ensures a square aspect ratio
    border-radius: 8px; // Optional, for rounded corners


    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; // Ensures images cover the area properly
        transition: transform 0.3s ease-in-out;
        border-radius: 8px; // Optional, for rounded corners
        transform-origin: center; // Set scaling origin to the center of the image
        box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.5);
    }

    &:hover img {
        transform: scale(1.5) translate(0%, -10%); // Default scale value
        box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.8);
        z-index: 2; // Ensures the image is above other elements when enlarged
    }

    &:hover.left img {
        transform: scale(1.5) translate(10%, -10%); // Translate when on the left
        box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
        z-index: 2; // Ensures the image is above other elements when enlarged
    }

    &:hover.right img {
        transform: scale(1.5) translate(-10%, -10%); // Translate when on the right
        box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
        z-index: 2; // Ensures the image is above other elements when enlarged
    }
}

.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10%;
    /* Center vertically within the viewport */
}


.portfolio-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $line-width solid $border-color;
    background-color: white;
    width: 500px;
    border-radius: 10px;

    @media (max-width: calc(4 * $min-card-width)) {
        width: 350px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain; // Ensures images cover the area properly
        border-radius: 10px 10px 0 0;
        opacity: 1 !important; // Set opacity to 1 and use !important to override inherited styles
    }

    p {
        padding: 10px;
        margin: 20px 10px;
        color: $border-color;
        border-radius: 8px;
        font-size: $bigger-font-size; // Smaller font size for the short description
    }
}

.report-padding {
padding: 5%;

    .report-button {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;

        background-color: $secondary-background-color; // Set your desired background color
        color: $primary-text-color; // Set your desired text color
        border: $line-width solid $border-color; // Set your desired border properties

        border-radius: 14px; // Set your desired border radius
        padding: 3px 8px; // Set your desired padding
        font-size: $standard-font-size; // Set your desired font size
        cursor: pointer;
        transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        opacity: 0.6;

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); // Set your desired shadow on hover
            opacity: 1; // Full opacity on hover
            text-decoration: none;
            transform: translateY(-2px); // Slight lift on hover/focus
        }
    }
}